import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset, signInWithEmailAndPassword } from 'firebase/auth'
import app from '../utils/firebase';

export default function NewPassword() {

    const [formData, setFormData] = React.useState({ password: '', passConfirm: '' })
    const [email, setEmail] = React.useState('')
    const [errorMsg, setErrorMsg] = React.useState('');
    const [successMsg, setSuccessMsg] = React.useState('');

    const navigate = useNavigate();

    const location = useLocation();

    const auth = getAuth(app)

    const params = new URLSearchParams(location.search);

    const oobCode = params.get('oobCode')
    if (!oobCode)
        navigate('/reset-password')

    React.useEffect(() => {


        verifyPasswordResetCode(auth, oobCode)
            .then(email => setEmail(email))
            .catch(error => {alert('This link has expired or is invalid.'); navigate('/reset-password')})
    }, [])


    function handleChange(event) {
        const { name, value } = event.target;

        setFormData(prevFormData => ({ ...prevFormData, [name]: value }))
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (formData.password.length < 6)
            setErrorMsg('Password must be at least 6 characters long')

        if (formData.password != formData.passConfirm)
            setErrorMsg('Passwords do not match');

        confirmPasswordReset(auth, oobCode, formData.password)
            .then(() =>
                signInWithEmailAndPassword(auth, email, formData.password)
                    .then(() => {
                        setSuccessMsg('Succesfully changed password. Logging you in...')
                        setTimeout(() => navigate('/'), 3000)
                    })
            )
            .catch((reason) => setErrorMsg(reason.code))
    }

    return (
        <div className='reset-password'>
            {email &&
                <form className='form' onSubmit={handleSubmit}>
                    <span>New Password for {email}</span>
                    <input
                        type="password"
                        placeholder='New Password'
                        name='password'
                        value={formData.password}
                        onChange={handleChange}
                    />
                    <input
                        type="password"
                        placeholder='Confirm New Password'
                        name='passConfirm'
                        value={formData.passConfirm}
                        onChange={handleChange}
                    />
                    {successMsg && <span className='success-msg'>{successMsg}</span>}
                    {errorMsg && <span className='error-msg'>{errorMsg}</span>}
                    <button className='form-btn'>Save Password</button>
                </form>
            }
        </div>
    )
}
