import React from 'react'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import app from '../utils/firebase.js'
import './ResetPassword.css'
import { useNavigate } from 'react-router-dom'

export default function ResetPassword() {

  const [formData, setFormData] = React.useState({ email: '' })
  const [errorMsg, setErrorMsg] = React.useState('');
  const [success, setSuccess] = React.useState(false)

  const auth = getAuth(app)
  const navigate = useNavigate();

  function handleChange(event) {
    const { name, value } = event.target;

    setFormData(prevFormData => ({ ...prevFormData, [name]: value }))
  }

  function handleSubmit(event) {
    event.preventDefault();
    sendPasswordResetEmail(auth, formData.email)
      .then(() => {
        setSuccess(true);
        setTimeout(() => navigate('/login'), 5000)

      })
      .catch((reason) => setErrorMsg(reason.code));

  }

  return (
    <div className='reset-password'>
      <form className='form' onSubmit={handleSubmit}>
        <span>Reset Password</span>
        <input
          type="email"
          name='email'
          placeholder='Email'
          value={formData.email}
          onChange={handleChange}
        />
        {errorMsg && <span className='error-msg'>{errorMsg}</span>}
        {success && <span className='success-msg'>An e-mail has been sent with instructions to reset your password</span>}
        <button className='form-btn'>Reset Password</button>

      </form>
    </div>
  )
}
