import { initializeApp } from 'firebase/app'
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth'
import { getFirestore, doc, query, collection, onSnapshot, QuerySnapshot, DocumentSnapshot, updateDoc, setDoc, deleteDoc, where, enableMultiTabIndexedDbPersistence, FirestoreError } from 'firebase/firestore';
import { nanoid } from 'nanoid';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBS2tQ6AIrlcPbDI3arHwd2Fn71opKXQYE",
    authDomain: "eurovision-party-778a6.firebaseapp.com",
    projectId: "eurovision-party-778a6",
    storageBucket: "eurovision-party-778a6.appspot.com",
    messagingSenderId: "857020341141",
    appId: "1:857020341141:web:605490c5614efd5f49f49c",
    measurementId: "G-QYRPT103JJ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)

enableMultiTabIndexedDbPersistence(db)

export default app;

export function createAccount(name, email, password) {
    const auth = getAuth(app);

    return createUserWithEmailAndPassword(auth, email, password).then(user => {
        const judgeId = createJudge(name, email)
        createUser(email, judgeId, user.user.uid);
    });



}

export const errorDescriptions = {
    'auth/email-already-in-use': 'This email address is already in use.'
}

/**
 * 
 * @param {*} event 
 * @param {(snapshot: QuerySnapshot<DocumentData>) => void} onNext 
 * @param {(error: FirestoreError) => void} onError 
 * @returns 
 */
export function onPerformances(event, onNext, onError) {
    const q = query(collection(db, event));
    return onSnapshot(q, onNext, onError);
}

/**
 * 
 * @param {*} event 
 * @param {(snapshot: DocumentSnapshot<DocumentData>) => void} callback 
 */
export function onPerformance(event, countryCode, callback) {
    const d = doc(db, event, countryCode);
    return onSnapshot(d, callback)
}

/**
 * 
 * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback 
 * @returns 
 */
export function onJudges(callback) {
    const q = query(collection(db, 'judges'))
    return onSnapshot(q, callback)
}

/**
 * 
 * @param {*} judgeId 
 * @param {(snapshot: DocumentSnapshot<DocumentData>) => void} onNext 
 * @param {(error: FirestoreError) => void} onError 
 * @returns 
 */
export function onJudge(judgeId, onNext, onError) {
    const d = doc(db, 'judges', judgeId)
    return onSnapshot(d, onNext, onError)
}

export function updateScores(judgeId, event, scores) {
    const d = doc(db, 'judges', judgeId);
    updateDoc(d, {
        [`scores.${event}`]: scores
    })
}

export function updateJudge(judgeId, data) {
    const d = doc(db, 'judges', judgeId);
    try {
        setDoc(d, data, { merge: true })
    }
    catch (error) {
        console.log(error)
    }

    const q = query(collection(db, 'users'), where('judge', '==', judgeId));
    const unsub = onSnapshot(q, (snapshot) => {
        snapshot.forEach((doc) => {
            updateDoc(doc.ref, { ...doc.data(), judge: "" })
        })
        if (data.user) {
            updateUser(data.user, { judge: judgeId })
        }
        unsub();
    })

}

function createJudge(name, user) {
    const id = nanoid();
    const d = doc(db, 'judges', id);
    setDoc(d, {
        name: name,
        user: user,
        id: id,
        scores: {
            'semi-final-1': {},
            'semi-final-2': {},
            'grand-final': {},
        }
    })

    return id;

}

export function deleteJudge(judgeId) {
    const d = doc(db, 'judges', judgeId);

    const q = query(collection(db, 'users'), where('judge', '==', judgeId));
    const unsub = onSnapshot(q, (snapshot) => {
        snapshot.forEach((doc) => {
            updateDoc(doc.ref, { judge: "" })
        })
        deleteDoc(d);
        unsub();
    })
}

/**
 * 
 * @param {(snapshot: QuerySnapshot<DocumentData>) => void} onNext 
 * @param {(error: FirestoreError) => void} onError 
 * @returns 
 */
export function onUsers(onNext, onError) {
    const q = query(collection(db, 'users'))
    return onSnapshot(q, onNext, onError)
}

export function updateUser(email, data) {
    const d = doc(db, 'users', email);
    try {
        updateDoc(d, data)
    } catch (error) {

    }

}

function createUser(email, judge, userUid) {
    const u = doc(db, 'users', email);

    setDoc(u, {
        role: 'user',
        judge: judge,
        ownerUID: userUid
    })
}

