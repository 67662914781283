export default function useDefaultEvent() {
    var today = new Date();
    var day = today.getDate();
    var month = today.getMonth() + 1;
    var year = today.getFullYear();

    if (year > 2022)
        return 'grand-final'

    if (month > 5)
        return 'grand-final'

    if (month < 5)
        return 'semi-final-1';

    if (day <= 10)
        return 'semi-final-1'

    if (day <= 12)
        return 'semi-final-2';

    return 'grand-final';


}
