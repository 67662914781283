import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from './useAuth'

export default function ProtectedRoute({ allowedRoles }) {

    const { user } = useAuth();

    if (!user)
        return <Navigate to='/login' />

    // if (!user.judge)

    if (allowedRoles && allowedRoles.length > 0 && !allowedRoles.includes(user.role))
        return <Navigate to='/' />

    return (
        <Outlet />
    )
}
