
export default function useLinks(role) {
    const items = [
        {
            name: "First Semi-Final", link: '/event/semi-final-1'
        },
        {
            name: "Second Semi-Final", link: '/event/semi-final-2'
        },
        {
            name: "Grand Final", link: '/event/grand-final'
        },
        {
            name: "Results", link: '/results'
        },

    ]

    if (role == 'admin')
        return [{ name: 'Judges', link: '/judges' }, {name: 'Edit Scores', link: '/edit-scores'}, ...items]

    return items
}