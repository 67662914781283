import React from 'react'
import { CircleFlag } from 'react-circle-flags'
import useAuth from '../utils/useAuth'
import { onJudges, onPerformances } from '../utils/firebase'
import './Results.css'
import useDefaultEvent from '../utils/useDefaultEvent'

export default function Results() {
    const [event, setEvent] = React.useState(useDefaultEvent())
    const [filterScores, setFilterScores] = React.useState(false)
    const [performances, setPerformances] = React.useState([])
    const [scores, setScores] = React.useState([])

    const positionScores = { 0: 12, 1: 10, 2: 8, 3: 7, 4: 6, 5: 5, 6: 4, 7: 3, 8: 2, 9: 1 }

    const { user } = useAuth();

    React.useEffect(() => {
        const unsubsribeJudges = onJudges((snapshot) => {
            const newScores = []
            snapshot.forEach(doc => {
                const judge = doc.data();
                if (!filterScores || judge.id === user.judge)
                    newScores.push(judge.scores[event] || {});
            })
            setScores(newScores);
        })

        const unsubsribePerformances = onPerformances(event, (snapshot) => {
            const newPerformances = [];
            snapshot.forEach(doc => {
                const performance = doc.data();
                newPerformances.push(performance);
            })
            setPerformances(newPerformances);
        })
        return () => { unsubsribeJudges(); unsubsribePerformances(); }
    }, [event, filterScores])

    

    const eurovisionScores = scores.map(judgeScores => {
        const scoreArray = [];
        for (const code in judgeScores) {
            if (Object.hasOwnProperty.call(judgeScores, code)) {
                const score = judgeScores[code];
                if (score)
                    scoreArray.push({ code: code, score: score })
            }
        }


        scoreArray.sort((a, b) => -1 * (a.score - b.score))

        let i = 0;

        while (i < scoreArray.length) {
            const score = scoreArray[i].score;
            let count = 1;

            while (i + count < scoreArray.length && scoreArray[i + count].score === score) {
                count++
            }

            let eurovisionScore = 0;

            for (let j = 0; j < count; j++) {
                const scoreDelta = positionScores[i + j] || 0;
                eurovisionScore += scoreDelta;
            }

            eurovisionScore = Math.floor(eurovisionScore / count);


            for (let j = i; j < i + count; j++) {
                scoreArray[j].score = eurovisionScore;
            }

            i += count;
        }

        const eurovisionScoreObj = arrayToObject(scoreArray, 'code');
        return eurovisionScoreObj
    })

    const performanceScores = performances.map(performance => {
        return {
            code: performance.code,
            country: performance.name,
            score: eurovisionScores.reduce((sum, euroScores) => { return sum + (euroScores[performance.code]?.score || 0) }, 0)
        }
    })

    performanceScores.sort((a, b) => (b.score - a.score) || (a.country.localeCompare(b.country)))

    let i = 0;
    while (i < performanceScores.length) {
        const score = performanceScores[i].score;
        let count = 1;

        performanceScores[i] = { ...performanceScores[i], position: i + 1 }

        while (i + count < performanceScores.length && performanceScores[i + count].score === score) {
            performanceScores[i + count] = { ...performanceScores[i + count], position: i + 1 }
            count++;
        }

        i += count;
    }

    function arrayToObject(array, key) {
        const newObj = {};
        array.forEach(el => {
            const newEl = { ...el };
            const keyValue = newEl[key];
            delete newEl[key];
            newObj[keyValue] = newEl;
        })
        return newObj;
    }

    return (
        <div>
            <div className='results--options'>
                <ul className='results--events'>
                    <li className={event === 'semi-final-1' ? 'results--options--selected' : ''} onClick={() => { setEvent('semi-final-1') }}>First Semi-Final</li>
                    <li className={event === 'semi-final-2' ? 'results--options--selected' : ''} onClick={() => { setEvent('semi-final-2') }}>Second Semi-Final</li>
                    <li className={event === 'grand-final' ? 'results--options--selected' : ''} onClick={() => { setEvent('grand-final') }}>Grand Final</li>
                </ul>
                <ul className='results--score-filter'>
                    <li className={!filterScores ? 'results--options--selected' : ''} onClick={() => setFilterScores(false)}>All scores</li>
                    <li className={filterScores ? 'results--options--selected' : ''} onClick={() => setFilterScores(true)}>My scores</li>
                </ul>
            </div>
            <div className='results'>

                <div className='results--scores'>
                    {performanceScores.map((performanceScore) => (
                        <div
                            key={performanceScore.code}
                            className='results--info'
                        >
                            <span className='results--position'> {performanceScore.position} </span>
                            <CircleFlag countryCode={performanceScore.code.toLowerCase()} height={20} />
                            <span className='results--country'> {performanceScore.country} </span>
                            <span className='results--score'>{performanceScore.score} </span>
                        </div>))}
                </div>
            </div>
        </div>
    )
}
