import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LogIn from './pages/LogIn';
import AuthProvider from './utils/AuthProvider';
import { Navigate } from 'react-router-dom';
import ProtectedRoute from './utils/ProtectedRoute';
import Navbar from './components/common/Navbar';
import Register from './pages/Register';
import Event from './pages/Event';
import EventPerformance from './pages/EventPerformance';
import Judges from './pages/Judges';
import EditScores from './pages/EditScores';
import Results from './pages/Results';
import ResetPassword from './pages/ResetPassword';
import useDefaultEvent from './utils/useDefaultEvent';
import NewPassword from './pages/NewPassword';


function App() {

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path='/login' element={<LogIn />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/register' element={<Register />}/>
          <Route path='/new-password' element={<NewPassword />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<Navbar />}>
              <Route path='*' element={<Navigate to={`/event/${useDefaultEvent()}`} replace={true}/>}></Route>
              <Route path='/event/:eventName' element={<Event />} />
              <Route path='/event/:eventName/:countryCode' element={<EventPerformance />} />
              <Route path='/results' element={<Results />} />
              <Route element={<ProtectedRoute allowedRoles={["admin"]} />}>
                <Route path='/judges' element={<Judges />} />
                <Route path='/edit-scores' element={<EditScores />}></Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
