import React from 'react'
import { CircleFlag } from 'react-circle-flags';
import './PerformanceCard.css'

export default function PerformanceCard({ performance, score, setScore, updateScores }) {


    React.useEffect(() => {
        adjustFontSize()
    }, [score])

    const handleChange = (event) => {
        var { value } = event.target;
        if (value === '') {
            setScore('')
            return;
        }

        value = Math.max(-10, Math.min(10, value))
        setScore(value.toString())
    }


    function adjustFontSize() {
        const size = Math.min(2.6 - 0.25 * Math.max(score?.length ?? 1, 1), 2.5)
        document.getElementById(`score-${performance.code}`).style.fontSize = size + 'rem';

    }

    function unicodeToChar(text) {
        return text.replace(/\\u[\dA-F]{4}/gi,
            function (match) {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
            });
    }

    return (
        <div className='performance-card' >
            {performance.yt ?
                <a className='performance-link' href={performance.yt}>
                    <img src={performance.img} alt={performance.artist} />
                    <span className='yt-logo'>{performance.yt && <i className="fa-brands fa-youtube"></i>}</span>
                </a> :
                <img src={performance.img} alt={performance.artist} />
            }

            <div className='performance-card--info'>
                <div className='performance-card--text'>
                    <span className='performance-card--artist'>{unicodeToChar(performance.artist)}</span>
                    <span className='performance-card--song'>{unicodeToChar(performance.song) || '-'}</span>

                </div>
                <input className='performance-card--input' id={`score-${performance.code}`} type="number" onChange={handleChange} value={score || ""} />
                <div className='performance-card--country'>
                    <span className='performance-card--position'>#{performance.position}</span>
                    <CircleFlag countryCode={performance.code.toLowerCase()} height="21px" />
                    <span>{performance.name}</span>
                </div>
            </div>
        </div>
    )
}
