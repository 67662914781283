import React from 'react'
import { AuthContext } from './useAuth'
import { db } from './firebase.js'
import { getDoc, doc, onSnapshot, collection, where, query, documentId } from 'firebase/firestore'
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import app from './firebase.js';

function AuthProvider({ children }) {

    const auth = getAuth(app);

    const [user, setUser] = React.useState(null);
    const [userUid, setUserUid] = React.useState(null);
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        const unsubscribeAuth = auth.onAuthStateChanged((currentUser) => {
            if (currentUser)
                setUserUid(currentUser.uid);
            else
                setUserUid(null)
        })


        return () => { unsubscribeAuth(); };
    }, [])

    React.useEffect(() => {

        if(!userUid)
        {
            setLoading(false);
            return;
        }

        const q = query(collection(db, 'users'), where('ownerUID', '==', userUid))
        const unsubscribeUser = onSnapshot(q, (snapshot) => {
            if (snapshot.size > 0) {
                setUser(snapshot.docs[0].data())
            }
            else {
                setUser(userUid ? { role: 'user' } : null)
            }
            setLoading(false);
        })

        return () => { unsubscribeUser(); }

    }, [userUid])



    function getUserInfo(fireUser) {
        const docRef = doc(db, 'users', fireUser.email)
        return getDoc(docRef).then((doc) => {
            if (!doc) {
                throw {
                    code: 404,
                    message: "unkown user: " + fireUser.email
                };
            }
            const data = doc.data()
            return data;

        })
    };

    /**
     * 
     * @param {{email: string, password: string}} userInfo credentials for logging in the user
     */
    const signin = (userInfo) => {
        return signInWithEmailAndPassword(auth, userInfo.email, userInfo.password)
            .then((cred) => {
                return getUserInfo(cred.user)
                    .then(newUser => {
                        setUser(newUser);
                    })
            })
    }

    /**
     * 
     */
    const signout = () => {
        setUser(null)
        return signOut(auth)
    }

    const value = { user, signin, signout }

    return (
        <AuthContext.Provider value={value} >{!loading && children}</AuthContext.Provider>
    )
}

export default AuthProvider