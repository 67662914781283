import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../utils/useAuth'
import useLinks from '../../utils/useLinks';
import './Sidebar.css'

export default function Sidebar(props) {

    const { user, signout } = useAuth();
    const links = useLinks(user.role);
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className='sidebar'>
            <div className='close-btn--container'>
                <button onClick={() => props.close()} className='sidebar--close-btn'><i className='fa fa-times'></i></button>
            </div>
            <div>
                <ul className='sidebar-nav--items'>
                    {links.map(link =>
                        <li
                            key={link.name}
                            className={`sidebar-nav--item ${location.pathname === link.link ? 'nav--selected' : ''}`}
                            onClick={() => { navigate(link.link); props.close(); window.scrollTo(0,0) }}>{link.name}
                        </li>
                    )
                    }
                </ul>
                <hr style={{ width: '60%', marginBlock: '25px' }} />
                <div className='sidebar-nav--item mobile-nav--logout' onClick={() => signout()}>
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                    <span> Log Out</span>
                </div>
            </div>
        </div>
    )
}
