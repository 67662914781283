import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../utils/useAuth.js'
import './Login.css'

function LogIn() {

    const [formData, setFormData] = React.useState({ email: "", password: "" })
    const [loginFailed, setLoginFailed] = React.useState(false);

    const { user, signin } = useAuth();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (user)
            navigate('/')
    }, [user])

    const handleSubmit = (event) => {
        event.preventDefault();
        signin(formData).catch((error) => { setLoginFailed(true); console.log(error) })
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }))
    }

    return (
        <div className='login'>
            <form onSubmit={handleSubmit} className='form'>
                <span>Log In</span>
                <input
                    className='login--email'
                    type="email"
                    name="email"
                    placeholder='Email'
                    value={formData.email}
                    onChange={handleChange}
                />
                <input
                    className='login--password'
                    type="password"
                    name="password"
                    placeholder='Password'
                    value={formData.password}
                    onChange={handleChange}
                />
                {loginFailed && <span className='error-msg'>Incorrect username/password</span>}
                <button className='form-btn'>Log In</button>
                <div className='form--link-container'>
                    <div>
                        <Link to='/reset-password' className='form--link'>Forgot your password?</Link>
                    </div>
                    <div>
                        <Link to='/register' className='form--link'>Don't have an account yet?</Link>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default LogIn