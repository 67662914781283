import React from 'react'
import useDefaultEvent from '../utils/useDefaultEvent'
import { onJudges, onPerformances } from '../utils/firebase'
import { CircleFlag } from 'react-circle-flags'
import { updateScores } from '../utils/firebase'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material'




import './EditScores.css'


export default function EditScores() {
    const [event, setEvent] = React.useState(useDefaultEvent())
    const [performances, setPerformances] = React.useState([])
    const [judges, setJudges] = React.useState([])

    React.useEffect(() => {
        const unsubsribeJudges = onJudges((snapshot) => {
            const newJudges = []
            snapshot.forEach(doc => {
                const judge = doc.data();
                newJudges.push(judge || {});
            })
            newJudges.sort((a, b) => {
                if (a.name === "") {
                    return 1;
                } else if (b.name === "") {
                    return -1;
                } else {
                    return a.name.localeCompare(b.name)
                }
            });
            setJudges(newJudges);
        })

        const unsubsribePerformances = onPerformances(event, (snapshot) => {
            const newPerformances = [];
            snapshot.forEach(doc => {
                const performance = doc.data();
                newPerformances.push(performance);
            })
            newPerformances.sort((p1, p2) => p1.position - p2.position)
            setPerformances(newPerformances);
        })
        return () => { unsubsribeJudges(); unsubsribePerformances(); }
    }, [event])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            judges.forEach(judge => {
                updateScores(judge.id, event, judge.scores[event]);
            })
        }, 5000);
        return () => { clearTimeout(timeout) }
    }, [event, judges])

    function handleChange(e) {
        const { name, value } = e.target;

        const judgeId = name.split(' ')[0];
        const code = name.split(' ')[1];

        setJudges(prevJudges => {
            const newJudges = [];
            for (let i = 0; i < prevJudges.length; i++) {
                const judge = prevJudges[i];
                if (judge.id == judgeId) {
                    judge.scores[event][code] = value
                }
                newJudges.push(judge)
            }

            console.log(newJudges)

            return newJudges
        })

    }

    const containerStyle = { overflowX: "initial" }

    const stickyStyle = { position: 'sticky', left: 0, backgroundColor: "white", width: "150px" };

    return (
        <div style={{height: "100%"}}>
            <div className='results--options'>
                <ul className='results--events'>
                    <li className={event === 'semi-final-1' ? 'results--options--selected' : ''} onClick={() => { setEvent('semi-final-1') }}>First Semi-Final</li>
                    <li className={event === 'semi-final-2' ? 'results--options--selected' : ''} onClick={() => { setEvent('semi-final-2') }}>Second Semi-Final</li>
                    <li className={event === 'grand-final' ? 'results--options--selected' : ''} onClick={() => { setEvent('grand-final') }}>Grand Final</li>
                </ul>
            </div>

            <TableContainer
                component={Paper}
                sx={{
                    height: "100%"
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{...stickyStyle, width: "10%"}}></TableCell>
                            {performances.map(performance => {
                                return <TableCell 
                                align='center' 
                                key={performance.code}
                                sx={{
                                    width: "10px"
                                }}
                                >
                                    <CircleFlag countryCode={performance.code.toLowerCase()} height="21px" />
                                </TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {judges.map(judge => {
                            return <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                className='edit--judge-row'
                                key={judge.id}>
                                <TableCell sx={{...stickyStyle}}>{judge.name}</TableCell>
                                {performances.map(performance => {
                                    return <TableCell className='edit--country-column' key={performance.code}>
                                        <input
                                            className='edit--input'
                                            type="number"
                                            name={`${judge.id} ${performance.code}`}
                                            value={judge.scores[event][performance.code] || ""}
                                            onChange={handleChange}
                                        />
                                    </TableCell>
                                })
                                }
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    )
}

