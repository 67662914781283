import React from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Sidebar from './Sidebar';
import './MobileNav.css'
import useLinks from '../../utils/useLinks';

export default function MobileNav() {

    const [collapsed, setCollapsed] = React.useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        
            document.body.style.overflow = collapsed ? 'unset' : 'hidden';
        
    }, [collapsed])


    const items = useLinks('admin')

    const loc = items.find(item => item.link == location.pathname).name
    

    return (
        <div className={`mobile-app ${!collapsed ? 'hide-overflow' : ''}`}>
            <nav className='mobile-nav'>
                <img src="https://static.eurovision.tv/hb-cgi/images/8d93886a-27ac-4996-bc17-2f12b47b2a0e.svg" alt="italy" className='nav--logo' />
                <span className='mobile-nav--location'>{loc}</span>
                <button className='sidebar-btn' onClick={() => setCollapsed(false)}><i className='fa fa-bars'></i></button>
                

            </nav>
            <main>
                <Outlet />
            </main>
            <div className={`modal-bg ${!collapsed ? '' : 'hide' }`}>

            </div>
            {!collapsed && <Sidebar close={() => setCollapsed(true)} />}
        </div>
    )
}


