import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import app, { createAccount, errorDescriptions } from '../utils/firebase';
import useAuth from '../utils/useAuth';
import './Register.css'

export default function Register() {

    const [formData, setFormData] = React.useState({ name: '', email: '', password: '', passConfirm: '' });
    const [errorMessage, setErrorMessage] = React.useState('');

    const navigate = useNavigate();

    const {user} = useAuth();

    React.useEffect(() => {
        if(user)
        {
            navigate('/')
        }
    }, [user])

    function handleSubmit(event) {
        event.preventDefault();

        if (formData.password.length < 6) {
            setErrorMessage('Password must be at least 6 characters long')
        }

        if (formData.password !== formData.passConfirm) {
            setErrorMessage('Passwords do not match')
            return;
        }

        createAccount(formData.name, formData.email, formData.password).catch((reason) => {
            console.log(reason.code);
            const description = errorDescriptions[reason.code] || 'An unexpected error occured';
            setErrorMessage(description);
        })
    }

    function handleChange(event) {
        const { name, value } = event.target;

        setFormData(prevFormData => (
            {
                ...prevFormData,
                [name]: value
            }))
    }

    return (
        <div className='register'>
            <form className='form' onSubmit={handleSubmit}>
                <span>Create New Account</span>
                <input
                    type="text"
                    placeholder='Name'
                    name='name'
                    value={formData.name}
                    onChange={handleChange}
                />
                <input
                    type="email"
                    placeholder='Email'
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                />
                <input
                    type="password"
                    placeholder='Password'
                    name='password'
                    value={formData.password}
                    onChange={handleChange}
                />
                <input
                    type="password"
                    placeholder='Confirm Password'
                    name='passConfirm'
                    value={formData.passConfirm}
                    onChange={handleChange}
                />
                {errorMessage && <span className='error-msg'>{errorMessage}</span>}
                <button className='form-btn'>Sign Up</button>
                <div className='form--link-container'>
                    <Link className='form--link' to='/login' >Already have an account?</Link>
                </div>
            </form>
        </div>
    )
}
