import React from 'react'
import PerformanceCard from '../components/PerformanceCard.js';
import { useLocation, useParams } from 'react-router-dom'
import { onJudge, onPerformances, updateScores } from '../utils/firebase.js';
import './Event.css'
import useAuth from '../utils/useAuth.js';

export default function Event() {

    const [performances, setPerformances] = React.useState([]);
    const [scores, setScores] = React.useState({})
    const [sortMethod, setSortMethod] = React.useState('position')
    const { eventName } = useParams();
    const location = useLocation();
    const { user } = useAuth();

    React.useEffect(() => {
        const unsubscribe = onPerformances(eventName,
            snapshot => {
                const newPerformances = [];
                snapshot.forEach(doc => newPerformances.push(doc.data()))
                setPerformances(newPerformances);
                sortPerformances();
            },
            error => {
                console.log(error)
            }
        )
        return () => unsubscribe();
    }, [location])

    React.useEffect(() => {
        if (!user.judge)
            return;
        const unsubscribe = onJudge(user.judge,
            (snapshot) => {
                if (!snapshot.exists())
                    return;
                const judge = snapshot.data()
                setScores(judge.scores[eventName] || {})
            },
            (error) => console.log('Error on judge')
        )
        return () => unsubscribe();
    }, [location, user.judge])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            updateScores(user.judge, eventName, scores);
        }, 1000);
        return () => { clearTimeout(timeout) }
    }, [location, scores])

    React.useEffect(sortPerformances, [sortMethod, scores])

    function sortPerformances() {
        setPerformances(prevPerformances => {

            const newPerformances = [...prevPerformances];
            if (sortMethod === 'position')
                newPerformances.sort((a, b) => a.position - b.position)
            else if (sortMethod === 'score')
                newPerformances.sort((a, b) => {
                    var aScore = scores[a.code];
                    var bScore = scores[b.code];

                    if (!aScore && aScore !== 0)
                        aScore = -10000;
                    if (!bScore && bScore !== 0)
                        bScore = -10000;

                    return -1 * (aScore - bScore) || (a.position - b.position);
                })
            return newPerformances
        })
    }

    function setScore(code, score) {
        setScores(prevScores => ({ ...prevScores, [code]: score }))
    }


    const performanceElements = performances.map(performance => {
        return (
            <PerformanceCard key={performance.code} performance={performance} score={scores[performance.code]} setScore={(score) => setScore(performance.code, score)} />
        )
    })

    return (
        <div className='event'>
            <div className='event--options'>
                {/* <svg className="fill-current text-black mr-4" width="25" height="20" viewBox="0 0 25 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.214 15.075a1.071 1.071 0 1 1 0 2.143H1.071a1.071 1.071 0 1 1 0-2.143h7.143zm1.429-4.285a1.071 1.071 0 0 1 0 2.142H1.07a1.071 1.071 0 1 1 0-2.142h8.572zm1.428-4.286a1.071 1.071 0 0 1 0 2.143h-10a1.071 1.071 0 0 1 0-2.143h10zM12.5 2.218a1.071 1.071 0 1 1 0 2.143H1.071a1.071 1.071 0 0 1 0-2.143H12.5zm1.968 11.168l2.9 3.214V1.071a1.071 1.071 0 0 1 2.143 0V16.1l2.892-2.857a1.179 1.179 0 0 1 1.608 0 1.071 1.071 0 0 1 0 1.514l-4.643 4.729a2.057 2.057 0 0 1-2.093 0l-4.286-4.643a1.071 1.071 0 0 1 .05-1.514c.192-.186.448-.288.714-.286a.979.979 0 0 1 .715.343z" fillRule="evenodd"></path></svg> */}
                <ul>
                    <li className={sortMethod === 'position' ? 'event--options--selected' : ''} onClick={() => { setSortMethod('position') }}>Running order</li>
                    <li className={sortMethod === 'score' ? 'event--options--selected' : ''} onClick={() => { setSortMethod('score') }}>Score</li>
                </ul>
            </div>
            <div className='performances'>{performanceElements}</div>
        </div>
    )
}
