import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../utils/useAuth'
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import './Navbar.css'

function Navbar() {
    const [useMobile, setUseMobile] = React.useState(false)

    React.useEffect(() => {
        const updateMobile = (event) => { setUseMobile(window.innerWidth < 660) }
        updateMobile();

        window.addEventListener('resize', updateMobile)
        return () => window.removeEventListener('resize', updateMobile)

    }, [])

    return (
        useMobile ? <MobileNav /> : <DesktopNav />
    )
}

export default Navbar