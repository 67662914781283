import React from 'react'
import { nanoid } from 'nanoid'
import { deleteJudge, onJudges, onUsers, updateJudge } from '../utils/firebase'
import Select from 'react-select';
import usePrevious from '../utils/usePrevious';
import './Judges.css'

export default function Judges() {
    const [judges, setJudges] = React.useState([])
    const [users, setUsers] = React.useState([]);

    const prevJudges = usePrevious(judges)


    React.useEffect(() => {
        const unsubscribeJudges = onJudges((snapshot) => {
            const newJudges = [];
            snapshot.forEach(doc => {
                const data = doc.data();
                newJudges.push(data)
            })
            newJudges.sort((a, b) => {
                if (a.name === "") {
                    return 1;
                } else if (b.name === "") {
                    return -1;
                } else {
                    return a.name.localeCompare(b.name)
                }
            });
            setJudges(newJudges)
        })

        const unsubscribeUsers = onUsers((snapshot) => {
            const newUsers = [];
            snapshot.forEach(user => {
                newUsers.push({
                    ...user.data(),
                    id: user.id
                })
            })
            setUsers(newUsers)
        },
        (error) => {console.log("failed users", error.code)})
        return () => { unsubscribeJudges(); unsubscribeUsers() }
    }, [])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            judges.forEach((judge, index) => {
                const prevJudge = prevJudges.find(j => j.id === judge.id);
                if (prevJudge) {
                    if (judge.name === prevJudge.name &&
                        judge.user === prevJudge.user)
                        return;
                }
                updateJudge(judge.id, judge)
            })
        }, 500);
        return () => clearTimeout(timeout);
    }, [judges])

    const userOptions = [
        { value: '', label: '(none)' },
        ...users.filter(user => !user.judge).map(user => ({ value: user.id, label: user.id }))
    ]

    function handleChange(event, judgeId) {
        const { name, value } = event.target;

        setJudges(prevJudges => {
            return prevJudges.map(judge => {
                if (judge.id !== judgeId)
                    return judge

                return {
                    ...judge,
                    [name]: value
                }
            })
        })

    }

    function onDeleteJudge(judgeId) {
        deleteJudge(judgeId)
    }

    function addJudge() {
        const newJudge = {
            id: nanoid(),
            name: '',
            user: '',
            scores: {
                'semi-final-1': {},
                'semi-final-2': {},
                'grand-final': {}
            }
        }

        setJudges((prevJudges) => [...prevJudges, newJudge])
    }

    const selectStyle = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: "none",
            backgroundColor: "#eae9eb"
        })
    };

    const judgeElements = judges.map(judge => {
        return (
            <div key={judge.id} className='judges-table--row'>
                <div className='judges-table--inputs'>
                    <div className='name-col'>
                        <input
                            type="text"
                            value={judge.name}
                            name='name'
                            onChange={(event) => handleChange(event, judge.id)}
                            className='judges-table--input'
                        />
                    </div>
                    <div className='user-col'>
                        <Select
                            options={userOptions}
                            value={{ value: judge.user, label: judge.user || '(none)' }}
                            name='user'
                            onChange={(newValue) => handleChange({ target: { name: 'user', value: newValue.value } }, judge.id)}
                            styles={selectStyle}
                        />
                    </div>
                </div>
                <div className='judges-table--btn'>
                    <button onClick={() => onDeleteJudge(judge.id)} className='judges--delete-btn'><i className='fa fa-times '></i></button>
                </div>
            </div>
        )
    })

    return (
        <div className='judges'>
            <div className='judges--table'>
                {judgeElements}
                <div className='judges--add-btn-container'>
                    <button onClick={addJudge} className='judges--add-btn'><i className='fa fa-add '></i></button>
                </div>
            </div>


        </div>
    )
}
