import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../utils/useAuth'

export default function DesktopNav() {
    const { user, signout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const items = [
        {
            name: "First Semi-Final", link: '/event/semi-final-1'
        },
        {
            name: "Second Semi-Final", link: '/event/semi-final-2'
        },
        {
            name: "Grand Final", link: '/event/grand-final'
        },
        {
            name: "Results", link: '/results'
        },
    ]


    const adminItems =
        [
            {
                name: 'Judges', link: '/judges'
            },
            {
                name: "Edit Scores", link: '/edit-scores'
            },
            ...items
        ]

    function toElement(item){
        return <li key={item.name} className={`nav--item ${location.pathname === item.link ? 'nav--selected' : ''}`} onClick={() => navigate(item.link)}>{item.name}</li>
    }


    return (
        <div>
        <nav className='desktop-nav'>
            <img src="https://static.eurovision.tv/hb-cgi/images/8d93886a-27ac-4996-bc17-2f12b47b2a0e.svg" alt="italy" className='nav--logo'/>
            <ul className='nav--items'>
                {user.role === 'admin' ? adminItems.map(toElement) : items.map(toElement)}
            </ul>
            <button className='nav--logout' onClick={() => signout()}><i className="fa-solid fa-arrow-right-from-bracket"></i></button>
        </nav>
        <main>
            <Outlet />
        </main>
        </div>
    )
}
